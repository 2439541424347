.form_container {
  display: flex;
  justify-content: center;
}

.right {
  width: 100%;
  max-width: 400px;
}

.google_btn {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
  font-size: 16px;
}

.google_btn img {
  width: 30px;
  margin-right: 10px;
}

/* Media queries */
@media (max-width: 768px) {
  .form_container {
    flex-direction: column;
    align-items: center;
  }
  
  .right {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .google_btn {
    font-size: 14px;
  }
  
  .google_btn img {
    width: 20px;
  }
}


/* Desktop view */
.form-group {
    display: inline-block;
    width: 45%;
    margin-right: 5%;
  }
  .form-group:nth-child(even) {
    margin-right: 0;
  }
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  input[type="tel"],
  textarea {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 15px;
  }
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Tablet view */
  @media screen and (max-width: 768px) {
    .form-group {
      width: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }
    .form-group:nth-child(even) {
      margin-right: 0;
    }
  }
  
  /* Mobile view */
  @media screen and (max-width: 480px) {
    input[type="tel"],
    textarea {
      font-size: 14px;
    }
    button {
      font-size: 16px;
      padding: 8px 16px;
    }
  }

  
  