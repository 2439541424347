@media (max-width: 767.98px) {
  .navbar-nav {
    flex-direction: column;
  }
  .nav-link {
    padding: 0.5rem 0;
  }
  .dropdown-toggle::after {
    display: none;
  }
}

@media (max-width: 767px) {
  .App {
    padding: 10px;
  }
}

/* For screens larger than 768px */
@media (min-width: 768px) {
  .App {
    padding: 20px;
  }
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
nav {
  height: 60px;
  line-height: 100%;
}