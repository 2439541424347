.container {
  width: 100vw;
  min-height: 100vh;
  background-color: #f0f8ff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .container{
    width: 50%;
    height: 320px;
  }
 }